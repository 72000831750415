import { createRouter, createWebHashHistory } from 'vue-router'
import { getCenterInfo, getViewStatus } from '@/api/public'
import { ElMessage } from 'element-plus';
const routes = [
  //后台
  {
    path:'/login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/activity',
    meta: {
      title: '包容页'
    },
    component: () => import('@/views/home'),
    children: [
      {
        path: '/home/activity',
        name: 'activity',
        meta: {
          title: ['活动管理'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/index')
      },
      {
        path: '/home/activity/c',
        name: 'activityC',
        meta: {
          title: ['活动管理', '证书管理'],
          routers: ['/home/activity'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/cetificate.vue')
      },
      {
        path: '/home/activity/c/congifg',
        name: 'config',
        meta: {
          title: ['活动管理', '证书管理','模版设置'],
          routers: ['/home/activity/c'],
          isCrumb: true 
        },
        component: () => import('@/views/activity/config.vue')
      },
      {
        path: '/home/search',
        name: 'search',
        meta: {
          title: ['证书查询'],
          isCrumb: true 
        },
        component: () => import('@/views/cetificateSearch/index'),
      },
    ]
  },
    // 前台
    // pc
  {
    path:'/p',
    component: () => import('@/views/pc/index.vue')
  },
  {
    path:'/pdetail',
    component: () => import('@/views/pc/detail.vue')
  },
  // h5
  {
    path:'/m',
    component: () => import('@/views/mobile/index.vue')
  },
  {
    path:'/mdetail',
    component: () => import('@/views/mobile/detail.vue')
  },

  {
    path: '/',
    redirect: '/p'
  },

  {
    path:'/preview',
    component: () => import('@/views/activity/preview.vue')
  },
  

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  }
});

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

router.beforeEach((to, from, next) => {

  if (to.fullPath.includes('/p') || to.fullPath.includes('/m')) {
    document.title = '证书查询'
  } else{
     document.title = '证书管理系统'
  }

  if (isMobile()) {
    // 重定向到移动端
    if (!to.path.startsWith('/m')) {
      next('/m');
    } else {
      next();
    }
  } else {
    // 重定向到pc端
    if (to.path.startsWith('/m')) {
      next('/p');
    } else {
      next();
    }
  }
});

// 路由守卫--start
// router.beforeEach((to, from, next) => {
//   getViewStatus({pid: 0}).then(res => {
//     if (!res.data) return ElMessage.error(res.msg);
//     // 未登录
//     if (!res.data.loginStatus) {
//       sessionStorage.clear();
//     };
//     // 登录
//     if (res.data.loginStatus) {
//       getCenterInfo({roleTypeId: 0, roleId: 5}).then(res => {
//         if (res.success) {
//           sessionStorage.setItem('loginMessage', JSON.stringify(res.data));
//           next();
//         } else {
//           // 清除所有登录信息
//           sessionStorage.clear();
//         };
//       });
//     };
//   });
// });

// 路由守卫--end
export default router
