<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script >
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  components: {
    ElConfigProvider,
  },
  setup () {
    let locale = zhCn
    
    return {
      locale
    };
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  // font-family: PingFang SC,Microsoft YaHei,Arial,sans-serif,Helvetica Neue,Helvetica,Hiragino Sans GB !important;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #F4F7F9;
}

.ql-editor{
  padding: 0 !important;
}
.ql-editor ol{
  padding-left:0px !important;
}
.ql-editor ul {
  padding-left:0px  !important;
}

</style>
